<template>
    <el-dropdown
        trigger="click"
        class="flex_margin_item"
        :style="customStyle"
        @visible-change="handleVisibleChange"
    >
        <el-button icon="el-icon-s-operation">列设置</el-button>
        <el-dropdown-menu slot="dropdown">
            <div class="column-setting-header">
                <el-row class="header-left">
                    <span>列设置</span>
                    <el-tooltip placement="top">
                        <template #content>
                            <div>鼠标长按可以拖动列的位置<br/>点击左侧框可控制列是否显示<br/>点击右侧开关可控制列是否固定</div>
                        </template>
                        <i class="el-icon-warning"></i>
                    </el-tooltip>
                </el-row>
                <el-button
                    type="primary"
                    plain
                    @click="resetTable()"
                    size="mini"
                >
                    重置
                </el-button>
            </div>
            <div class="column-setting-sub-header">
                <span>显示</span>
                <span class="label">列名</span>
                <span>固定列</span>
            </div>
            <el-tree
                draggable
                :data="tableListData"
                :props="defaultProps"
                :allow-drop="allowDrop"
                @node-drop="handleDrop"
            >
                <el-row class="tree-table-setting" slot-scope="{ node, data }" type="flex" justify="space-between">
                    <div class="setting-left">
                        <el-checkbox v-model="data.show" @change="saveTableColumns"></el-checkbox>
                        <span>{{ data.label }}</span>
                    </div>
                    <el-switch
                        size="small"
                        @change="saveTableColumns"
                        v-model="data.fixed"
                    ></el-switch>
                </el-row>>
            </el-tree>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { getConfig, saveConfig } from "@/api/common";
import { apiCallback } from "@/utils";
export default {
    name: "ColumnSet",
    props: {
        tableList: {
            type: Array,
            default: () => [],
        },
        pageKey: {
            type: String,
        },
        resetData: {
            type: Object,
            default: () => ({}),
        },
        saveMutation: {
            type: Object | String,
            default: () => {
                return { NB: "table/editTableList" };
            },
        },
        resetMutation: {
            type: Object | String,
            default: () => {
                return { NB: "table/resetTableList" };
            },
        },
        customStyle: {
            type: Object,
            default: () => ({}),
        },
        useSystemKey: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasChange: false,
            defaultProps: {
                children: "children",
                label: "label",
            },
        };
    },
    mounted() {
        this.getUserColumns();
    },
    computed: {
        storeSystemKey() {
            return this.useSystemKey ? this.$store.state.other.systemKey : "NB";
        },
        resetTableData() {
            return this.resetData[this.storeSystemKey];
        },
        resetTableMutation() {
            const mutationType = typeof this.resetMutation;
            if (mutationType === "string") {
                return this.resetMutation;
            } else {
                return this.resetMutation[this.storeSystemKey];
            }
        },
        saveTableMutation() {
            const mutationType = typeof this.saveMutation;
            if (mutationType === "string") {
                return this.saveMutation;
            } else {
                return this.saveMutation[this.storeSystemKey];
            }
        },
        computedDictKey() {
            return this.useSystemKey && this.storeSystemKey !== "NB"
                ? `web_fangcangwang_${this.pageKey}_columns_${this.storeSystemKey}`
                : `web_fangcangwang_${this.pageKey}_columns`;
        },
        tableListData: {
            get() {
                return this.tableList;
            },
            set(val) {
                this.$store.commit(this.saveTableMutation, val);
            },
        },
    },
    watch:{
        computedDictKey(){
            this.getUserColumns()
        }
    },
    methods: {
        getUserColumns() {
            getConfig({
                dictKey: this.computedDictKey,
            })
                .then((res) => {
                    const { dictValue } = apiCallback(res) || {};
                    if (dictValue) {
                        const selectedColumns = dictValue.split(",");
                        const cacheColumns = [...this.resetTableData];
                        const newColumns = selectedColumns.map((item) => {
                            const [prop, show, fixed] = item.split("-");
                            const curColumn = cacheColumns.find(
                                (column) => column.prop === prop,
                            );
                            if (curColumn) {
                                curColumn.show = show === "1";
                                if (fixed) {
                                    curColumn.fixed = fixed === "1";
                                }
                                return curColumn;
                            }
                        });
                        if (newColumns.length < cacheColumns.length) {
                            // 如果从服务端获取列数小于本地配置的列数，说明有新添加的列
                            cacheColumns.forEach((item, index) => {
                                const isAddColumn = !newColumns.find(
                                    (column) => column.prop === item.prop,
                                );
                                if (isAddColumn) {
                                    item.show = true;
                                    newColumns.splice(index, 0, item);
                                }
                            });
                        }
                        // const newColumns = cacheColumns.map((item) => {
                        //     const curColumn = selectedColumns.find(
                        //         (column) => column.split("-")[0] === item.prop,
                        //     );
                        //     if (curColumn) {
                        //         const [prop, show] = curColumn.split("-");
                        //         item.show = show === "1";
                        //     } else {
                        //         // 如果没有配置，是新添加的字段，默认显示
                        //         item.show = true;
                        //     }
                        //     return item;
                        // });
                        this.$store.commit(this.resetTableMutation, newColumns);
                    }
                })
                .catch((err) => {
                    this.$store.commit(
                        this.resetTableMutation,
                        [...this.resetTableData],
                    );
                });
        },
        getShowColumnsProp() {
            // 获取当前显示的列，记录prop和是否显示
            const result = this.tableListData.map((item) => {
                return `${item.prop}-${item.show ? 1 : 0}-${item.fixed ? 1 : 0}`;
                // return `${item.prop}-${item.show ? 1 : 0}`;
            });
            return result;
        },
        handleVisibleChange(value) {
            if (!value && this.hasChange) {
                saveConfig({
                    dictKey: this.computedDictKey,
                    dictValue: this.getShowColumnsProp().join(","),
                });
            }
        },
        allowDrop(draggingNode, dropNode, type) {
            // 仅允许Tree节点上下拖动
            return type !== "inner";
        },
        // Tree 拖动时更新表格
        handleDrop() {
            this.tableKey++;
            this.saveTableColumns();
        },
        // 重置表格
        resetTable() {
            this.hasChange = true;
            const cacheTableData = this.resetTableData.map((item) => {
                return {
                    ...item,
                    show: true,
                    fixed: false
                }
            });
            this.$store.commit(this.resetTableMutation, [...cacheTableData]);
            this.tableListData = [...cacheTableData];
            this.tableKey = 1;
        },
        // 显示隐藏切换 && 保存表格配置
        saveTableColumns() {
            this.hasChange = true;
            this.$store.commit(this.saveTableMutation, this.tableListData);
        },
    },
};
</script>

<style lang="stylus" scoped>
.el-tree {
  padding: 0 8px;
  max-height: 50vh;
  overflow: auto;
  /deep/.el-tree-node__expand-icon{
        display: none;
    }
}
.flex_margin_item{
  margin: 10px 0px;
}
.tree_btn_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}
.column-setting-header{
    display: flex;
    padding: 0 8px 8px 8px;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    .header-left{
        span{
            margin-right: 4px;
        }
        font-size: 15px;
        font-weight: bold;
    }
}
.column-setting-sub-header{
    display: flex;
    width: 180px;
    margin: 0 auto;
    padding: 0 8px 4px 0;
    box-sizing: border-box;
    span{
        font-size: 12px;
        font-weight: bold;
    }
    span.label{
        margin-left: 6px;
        flex-grow: 1;
    }
}
.tree-table-setting{
    width: 180px;
    // border: 1px solid #ccc;
    // border-radius: 2px;
    padding: 4px 8px;
    .setting-left{
        span{
            margin-left: 8px;
            font-size: 14px;
        }
    }
}
</style>
